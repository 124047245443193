<template>
    <div class="col-12 tower-overview">
        <h4> Information du château 
            <small class="text-right"> Dernière mise à jour: {{ lastDate |showTime }}</small>
        </h4>
         <div class="row">
            <div class="col-6">
                <label>Niveau de l'eau: {{ niveau }}</label>
            </div>
            <div class="col-6">
                <label>Pourcentage du niveau d'eau: {{ pourcentageNiveau }}</label>
            </div>
         </div>
         <div class="row">
            <div class="col-6">
                <label>Volume d'entrée:</label>
            </div>
         </div>
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex';
export default{
    props: {
        host: {type: String, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            towerData: 'host/towerData',
            hosts: 'host/hosts'
        }),
        hostObject(){
            return this.hosts.find(item => item.uid === this.host)
        },
        hasTowerController(){
            if(this.hostObject) return this.hostObject.hasTowerController
            return false
        },
        lastDate(){
            return new Date(Math.max([...this.towerData].filter(item => item.host === this.host).map(e => new Date(e.date))));
        },
        lastData(){
            const sortedArray = [...this.towerData].filter(item => item.host === this.host).sort((a, b) => a.MeasureDate.localeCompare(b.MeasureDate));
            return sortedArray[sortedArray.length - 1];
        },
        
        niveau(){
            if(this.lastData !== null){
                return this.lastData.niveau
            }
            return 0
        },
        pourcentageNiveau(){
            if(this.hostObject) return (parseFloat(this.lastData.niveau) * 100)/ parseFloat(this.hostObject.volume)
            return 0
        }
    }
}
</script>

<style lang="scss">
.tower-overview{
    h4{
        font-size: 25px;
    }
}
</style>