<template>
    <div class="">
        <navbar :items="navItems"/>
        <div class="row" style="margin-bottom: 20px">
            <div class="col-md-7">
                <div class="row">
                    <div class="col-5">
                        <div class="detail-info">
                        <label> Modèle</label>
                        {{ model }}
                        </div>
                        <div class="detail-info">
                            <label> Version</label>
                            {{ version }}
                        </div>
                        <div class="detail-info">
                            <label> Firmware</label>
                            {{ firmware }}
                        </div>
                        
                        <div class="">
                            <label> Options</label>
                        </div>
                        <div class="options">
                            <div class="option" v-for="(o,i) in options" :key="i">
                                <!-- {{ options[i] }}  -->
                                <label>{{ o }}</label>
                                <!-- {{ Object.values(options)[i] }}
                                <label> {{ Object.keys(options)[i] }}</label> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="detail-info">
                            <label> Numéro</label>
                            {{ numero }}
                        </div>
                        <div class="detail-info">
                            <label> Imei</label>
                            {{ imei }}
                        </div>
                        <div class="detail-info">
                            <label> Longitude</label>
                            {{ long }}
                        </div>
                        <div class="detail-info">
                            <label> Latitude</label>
                            {{ lat }}
                        </div>
                        <div class="detail-info">
                            <label> Dernière transactoin</label>
                            {{ lastActivity|showTime }}
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-12"><tower-controller-overview /></div>
                        
                    </div>
                    <div class="col-12 text-center">
                        <a href="javascript:void(0)" class="btn btn-primary" data-toggle="modal" data-target="#qrcode">
                           <span style="font-size: 16px;">Cliquez ici pour recuperer le QRcode de configuration</span> <i class="icofont icofont-qr-code icofont-2x"></i>
                        </a>
                    </div>
                    
                </div>

                
            </div>
            <div class="col-md-5">
                <host-map :host="host"/>
            </div>
            
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <div class="statbox widget">
                    <div class="widget-header">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                <h4>Données de terrain</h4>
                            </div>
                        </div>
                    </div>
                    <div class="widget-content widget-content-area tab-horizontal-line">
                        <ul class="nav nav-tabs  mb-3" id="animateLine" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="animated-underline-client-tab" data-toggle="tab" href="#animated-underline-client" role="tab" aria-controls="animated-underline-client" aria-selected="true"> 
                                    Clients ({{filtredClients.length}})
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="animated-underline-about-tab" data-toggle="tab" href="#animated-underline-about" role="tab" aria-controls="animated-underline-about" aria-selected="false">
                                    Historique des activités ({{ filtredHistoriques.length }})
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="animated-underline-messages-tab" data-toggle="tab" href="#animated-underline-messages" role="tab" aria-controls="animated-underline-messages" aria-selected="false"> 
                                    Recharges ({{filtredRecharges.length}})
                                </a>
                            </li>
                            <li class="nav-item" v-if="hasTowerController">
                                <a class="nav-link" id="animated-underline-messages-tab" data-toggle="tab" href="#animated-underline-chateau" role="tab" aria-controls="animated-underline-messages" aria-selected="false"> 
                                    Informations du château ({{filtredTowerData.length}})
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="animateLineContent-4">
                            <div class="tab-pane active fade show" id="animated-underline-client" role="tabpanel" aria-labelledby="animated-underline-client-tab">
                                <data-table :dataSource="filtredClients" :headers="clientHeaders"/>
                            </div>
                            <div class="tab-pane fade" id="animated-underline-about" role="tabpanel" aria-labelledby="animated-underline-about-tab">
                                <data-table :dataSource="filtredHistoriques" :headers="historiqueHeaders"/>
                            </div>
                            <div class="tab-pane fade" id="animated-underline-messages" role="tabpanel" aria-labelledby="animated-underline-messages-tab">
                                <data-table :dataSource="filtredRecharges" :headers="rechargeHeaders"/>
                            </div>
                            <div class="tab-pane fade" id="animated-underline-chateau" role="tabpanel" aria-labelledby="animated-underline-messages-tab" v-if="hasTowerController">
                                <data-table :dataSource="filtredTowerData" :headers="chateauHeaders"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal id="qrcode"  :footer="false" title="Qrcode de configuration">
            <div class="row">
                <div class="col-12">
                    <qr-code :text="qrCodeValue"/>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import hostMap from '../../components/host/host/miniMap.vue'
import TowerControllerOverview from '../../components/host/detail/towerControllerOverview.vue'
import DataTable from '../../components/dataTable/local.vue'
import Navbar from '../../components/navBar.vue'
import Modal from '../../components/modal.vue'
import {TEXT_TYPE, NUMERIC_TYPE, DATE_TYPE, COMPONENT_TYPE} from '../../components/dataTable/dataType'
const ClientFullname = () => import ('../../components/host/detail/clientFullname.vue')
const PourcentageNiveau = () => import('../../components/host/detail/towerControllerPourcentage.vue')
import qrCode from 'vue-qrcode-component'
import moment from 'moment'
export default {
    components: {Navbar, hostMap, DataTable, Modal, qrCode, TowerControllerOverview },
    data(){
        return {
            navItems: [
                {name: 'Liste des sites', route: 'hosts'},
                {name: 'Detail'}
            ],
            host: null, 
            optionsValue:{
                'feed_back': 'Remontée données',
                'mobile_payment': "Paiement mobile"
            } 
                
            
        }
    },
    watch: {
        hosts: {
            handler(){
                this.host = this.hosts.find(item => item.uid === this.$route.params.uid)
            },
            deep: true
        }
    },
    beforeMount(){
        this.host = this.hosts.find(item => item.uid === this.$route.params.uid)
    },
    methods: {
        onDataUrlChange() {
            // this.dataUrl = dataUrl
        },
    },
    computed: {
        ...mapGetters({
            hosts: 'host/hosts',
            clients: 'host/clients',
            historiques: 'host/historiques',
            recharges: 'host/recharges',
            models: 'host/models',
            versions: 'host/versions',
            firmwares: 'host/firmwares',
            towerData: 'host/towerData'
        }),
        hasHost(){return this.host !== null && this.host !== undefined},
        hasTowerController(){
            if(this.hasHost) return this.host.hasTowerController
            return false
        },
        numero(){return this.hasHost? this.host.numero : null},
        imei(){
            return this.hasHost? this.host.imei : null
        },
        long(){
            return this.hasHost? this.host.long : null
        },
        lat(){
            return this.hasHost? this.host.lat : null
        },
        nbClient(){
            return this.hasHost? this.host.nbClient : null
        },
        model(){
            if(this.hasHost){
                let m = this.models.find(item => item.uid === this.host.model)
                return m? m.name : null
            } 
            return null
        },
        version(){
            if(this.hasHost){
                let v = this.versions.find(item => item.uid === this.host.version)
                return v? v.name : null
            } 
            return null
        },
        firmware(){
            if(this.hasHost){
                let f = this.firmwares.find(item => item.uid === this.host.firmware)
                return f? f.libelle: null
            } 
            return null
        },
        key() {
            return this.hasHost? this.host.key : null
        },
        
        qrCodeValue(){
            return JSON.stringify({
                imei: this.imei,
                token: this.host.key,
                nb_client: this.nbClient.toString(),
                feed_back: this.options.feed_back? "1" : "0",
                mobile_payment: this.options.mobile_payment? "1" : "0",
                state: "1",
                imei_module: this.imei,
                date: Math.floor(Date.now()/1000).toString()
            })
        },
        options(){return this.hasHost? JSON.parse(this.host.options) : []},
        
        lastActivity(){return this.hasHost? this.host.lastActivity : null},

        filtredClients(){
            if(this.clients.length > 0) return [...this.clients].filter(item => item.host === this.host.uid).sort((a,b) => a.fullname.localeCompare(b.fullname))
            return []
        },
        filtredRecharges(){
            return [...this.recharges].filter(item => item.host === this.host.uid).sort((b, a) => moment(a.date).format('X')  - moment(b.date).format('X'))
        },
        filtredHistoriques(){
            return [...this.historiques].filter(item => item.host === this.host.uid).sort((b, a) => moment(a.date).format('X')  - moment(b.date).format('X'))
        },
        filtredTowerData(){
            return [...this.towerData].filter(item => item.host === this.host.uid).sort((b, a) => moment(a.date).format('X')  - moment(b.date).format('X'))
        },
        clientHeaders(){
            return [
                {label: 'Nom complet', name: 'fullname', type: TEXT_TYPE, sortable: true},
                {label: 'Téléphone', name: 'telephone', type: TEXT_TYPE, sortable: true},
                {label: 'Volume (ml)', name: 'volume', type: NUMERIC_TYPE, sortable: true},
                {label: 'Dernière activité', name: 'lastActivity', type: DATE_TYPE, sortable: true},
                
            ]
        },
        historiqueHeaders(){
            return [
                {label: 'Client', name:'client', type: COMPONENT_TYPE, component: ClientFullname, sortable: true},
                {label: 'Volume (ml)', name: 'volume', type: NUMERIC_TYPE, sortable: true},
                {label: 'Badge', name: 'badge', type: TEXT_TYPE, sortable: true},
                {label: 'Date', name: 'date', type: DATE_TYPE, sortable: true},
            ]
        },
        rechargeHeaders(){
            return [
                {label: 'Client', name:'client', type: COMPONENT_TYPE, component: ClientFullname, sortable: true},
                {label: 'Volume (ml)', name: 'volume', type: NUMERIC_TYPE, sortable: true},
                {label: 'Prix (FCFA)', name: 'prix', type: NUMERIC_TYPE, sortable: true},
                {label: 'Date', name: 'date', type: DATE_TYPE, sortable: true},
            ]
        },
        chateauHeaders(){
            return [
                {label: 'Niveau d\'eau (L)', name:'niveau', type: NUMERIC_TYPE,  sortable: true},
                {label: 'Pourcentage de niveau (%)', name: 'volume', type: COMPONENT_TYPE, component: PourcentageNiveau },
                {label: 'Distance du capteur', name: 'distance', type: NUMERIC_TYPE, sortable: true},
                {label: 'Volume d\'eau entrant', name: 'distance', type: NUMERIC_TYPE, sortable: true},
                {label: 'Date', name: 'date', type: DATE_TYPE, sortable: true},
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.detail-info{
    padding: 10px 10px;
    &:not(:last-child){
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.15);
    }
    label{
        width: 30%;
        font-weight: 500;
    }
}
</style>