<template>
    <div v-if="host !== null">
        <gmap-map
      :center="center"
      :zoom="7"
      style="width:100%; height: 300px"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"
      >
        <template>
          <marker-detail :marker="marker" />
        </template>
      </gmap-info-window>
      <gmap-cluster>
        <gmap-marker
          :position="marker.position"
          :title="marker.title"
          :clickable="true"
          @click="toggleInfoWindow(marker,index)"
        />
      </gmap-cluster>
    </gmap-map>
    </div>
</template>
<script>
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import MarkerDetail from './marker.vue'
export default {
    props: {
        host: { type: Object, require: true}
    },
    components: { GmapCluster, MarkerDetail },
    data() {
        return {
            infoContent: '',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            infoOptions: {
                pixelOffset: {
                width: 0,
                height: -35
                }
            }
        }
    },
    methods: {
    toggleInfoWindow (marker, idx) {
      // console.log('click on marker')
      this.infoWindowPos = marker.position

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      }
      // if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  },
  computed: {
      marker () {
        return {
            title: this.host.uid,
            position: { lat: this.host.lat, lng: this.host.long },
            // details: {
            //   code: this.elevage.code_elevage,
            //   sentinelle: this.elevage.sentinelle,
            //   geographic_params: this.elevage.parametre_geographique,
            //   type_elevage: this.elevage.type_elevage.fr,
            //   especes: this.elevage.especes
            // }
        }
    },
    zoom () {
      return 7
    },
    center () {
      return {
        lat: this.host.lat,
        lng: this.host.long
      }
    }
  }
}
</script>